import { hash } from 'ohash'
import Cookie from 'js-cookie'
// import { FetchResponse } from 'ofetch/dist/node'
import { showToast } from 'vant'
// import { useSharedStore } from '~/stores/shared'
import {
  EJavaBusinessResponseCode,
  EPHPBusinessResponseCode
} from '~/enums/request'
// const baseUrl = ''

export const useRequest = ({ url, ...options }: any): Promise<any> => {
  // const host = ''
  // const loggerInfo = ''
  const headers = useRequestHeaders()
  const runtimeConfig = useRuntimeConfig()
  // const nuxtApp = useNuxtApp()
  // const envConfig = useRuntimeConfig()

  // JAVA_INNER_HOST, PHP_INNER_HOST
  const { JAVA_HOST, PHP_HOST } =
    runtimeConfig.public

  let BASE_URL: any = ''

  if (process.server) {
    BASE_URL = options.open ? PHP_HOST : JAVA_HOST
  } else {
    BASE_URL = `${options.open ? PHP_HOST : JAVA_HOST}`
  }

  // console.log(`process.server: ${process.server}`)

  // const versionId = ''
  // let referer = ''
  const customHeader: any = {
    platform: 2,
    language: 'zh_CN'
  }

  // config.headers.device_model = 'web'

  if (process.server) {
    customHeader['x-original-forwarded-for'] = getIp(options)
    customHeader.access_token = getCookie('C5AccessToken') || getCookie('H5_C5_AccessToken') || ''
    customHeader.app_version_code = getCookie('C5AppVersion') || '401000'
    customHeader.channel = getCookie('C5Channel') || ''
    // console.log(
    //   "useCookie('C5AccessToken').value",
    //   useCookie('C5AccessToken').value
    // )
    // referer = headers.referer
  }

  if (options?.urlencoded) {
    customHeader['content-type'] = 'multipart/form-data'
  }

  if (options.method === 'POST' && options.open) {
    options.params = options.query
  }

  function getCookie (name: any) {
    if (!document) { return }
    const arr = document?.cookie.split('; ') // 多个cookie值是以; 分隔的，用split把cookie分割开并赋值给数组
    // console.log('request===>getCookie===>arr', arr)
    for (
      let i = 0;
      i < arr.length;
      i++ // 历遍数组
    ) {
      const arr2 = arr[i].split('=') // 原来割好的数组是：user=simon，再用split('=')分割成：user simon 这样可以通过arr2[0] arr2[1]来分别获取user和simon
      // console.log('request===>getCookie===>arr2', arr2)
      if (arr2[0] === name && arr2[1]) {
        // console.log(
        //   'request===>getCookie===>arr2[1]',
        //   arr2[1],
        //   'arr2[0]',
        //   arr2[0],
        //   'name',
        //   name
        // )
        // 如果数组的属性名等于传进来的name
        return arr2[1] // 就返回属性名对应的值
      }
    }
  }

  // 向请求头中塞入 token
  if (process.client) {
    // console.log('client request for =>>>>', config, window.location)
    // if (Cookie.get('C5AccessToken')) {
    //   customHeader.access_token = Cookie.get('C5AccessToken')
    // }

    if (getCookie('C5AccessToken')) {
      customHeader.access_token = getCookie('C5AccessToken')
      // console.log('cookie->access-token', getCookie('C5AccessToken'))
    } else if (getCookie('H5_C5_AccessToken')) {
      customHeader.access_token = getCookie('H5_C5_AccessToken')
      // console.log('cookie->access-token', getCookie('H5_C5_AccessToken'))
    }

    customHeader.app_version_code = Cookie.get('C5AppVersion') || '401000'

    customHeader.channel = Cookie.get('C5Channel') || 'iOS'

    const douyuTag = Cookie.get('douyuTag') ? Cookie.get('douyuTag') : ''
    const from = Cookie.get('NC5_sem_from') ? Cookie.get('NC5_sem_from') : ''
    const keyword = Cookie.get('NC5_sem_keyword')
      ? Cookie.get('NC5_sem_keyword')
      : ''
    const clickExt = Cookie.get('NC5_sem_clickExt')
      ? Cookie.get('NC5_sem_clickExt')
      : ''
    const bdvid = Cookie.get('bd_vid') ? Cookie.get('bd_vid') : ''
    const sem360id = Cookie.get('NC5_sem360id')
      ? Cookie.get('NC5_sem360id')
      : ''
    const refererUrl = Cookie.get('refererurl') ? Cookie.get('refererurl') : ''

    if (douyuTag || from || keyword || bdvid || sem360id) {
      customHeader['x-channel'] = `${douyuTag || from};${keyword};${
        bdvid || clickExt || sem360id
      }`
      customHeader['x-referral-url'] = `${refererUrl}`
    }

    if (url.indexOf('http://') <= -1 && url.indexOf('https://') <= -1) {
      // eslint-disable-next-line no-empty
      if (options.open) {
      } else {
        customHeader.language = headers.language
          ? headers.language.replace('-', '_')
          : 'zh_CN'
      }
      url = BASE_URL.includes('http')
        ? `${BASE_URL}${url}`
        : `${window.location.origin}${BASE_URL}${url}`
    }
  }

  customHeader.device = isInApp ? (customHeader.channel === 'iOS' ? 3 : 2) : 4
  customHeader.device_id = Cookie.get('C5DeviceId') ? Cookie.get('C5DeviceId') : ''

  // console.log('cookie', cookie)

  // console.log("Cookie.get('C5Channel') ", Cookie.get('C5Channel'))

  // console.log('headers', headers)

  // console.log('customHeader', customHeader)

  const optionObj = {
    ...options
  } as any
  // 设置key
  const key = hash(+new Date() + url)
  optionObj.headers = {
    platform: 2,
    // device_id: 'iPhone-CA88F2FB-4484-4A8F-B8B1-ED649E0272EC',
    ...options.headers,
    ...customHeader
  }
  optionObj.key = key
  // 兼容fetch body参数
  optionObj.body = optionObj.data ? optionObj.data : null
  optionObj.query = optionObj.params || {}

  // console.log('request url:', `${url}`, `process:`, process.server)
  // await nextTick() //解决刷新页面useFetch无返回
  return new Promise((resolve, reject) => {
    useFetch(url, {
      ...optionObj,
      onRequestError ({ error }) {
        // 处理请求错误
        console.log('服务器链接失败!')
        reject(error)
      },
      onResponse ({ response }) {
        // const redirectPath = encodeURIComponent(location?.href)
        // console.log('=====onResponse=====', response)
        // console.log('=====onResponse data=====', response._data)
        // console.log('=====errorCode=====', response._data?.errorCode, response._data?.status)
        const data = response._data
        if (
          (data?.errorCode && data?.errorCode !== 0) ||
          (data?.status && data?.status !== 200)
        ) {
          // TODO toast 错误提示 ====》(根据传值判断是否需要展示)
          // console.error('错误提示', data)

          // 聚合错误信息
          const errorMessage = data?.message || data?.errorMsg || ''
          const errorCode = data?.status || data?.errorCode || ''
          // 针对未登录 及 请求重复 errorCode 不显示toast提示
          if(data?.errorCode !== 101 && data?.errorCode !== 30027 && data?.errorCode !== 'REPEAT_REQUEST')
            showToast(errorMessage)

          console.log('===errorCode===', errorCode)

          switch (errorCode) {
            case EJavaBusinessResponseCode.NOT_LOGIN:
            case EPHPBusinessResponseCode.NOT_LOGIN:
              console.log('未登录')
              if (isInApp) {
                process?.server
                  ? native?.login()
                  : native?.loginBackPage(location?.href)
              } else {
                // console.log('==location?.href==', location?.href)
                // let redirectString = '/login'
                // const url = new URL(location?.href || '')
                // const path = url.pathname
                // const query = url.search
                // if (!query?.includes('redirect_url')) {
                //   redirectString += '?redirect_url=' + path
                //   redirectString += encodeURIComponent('?' + query)
                // }
                Cookie.remove('H5_C5_AccessToken')
                navigateTo('/login', { replace: true })
              }
              break
            case EJavaBusinessResponseCode.NOT_FOUND:
            case EJavaBusinessResponseCode.NOT_FOUND_4004:
              console.log('404')
              break
            case EJavaBusinessResponseCode.SERVER_ERROR:
            case EJavaBusinessResponseCode.SERVER_ERROR_999:
              console.log('服务器异常')
              break
            case EJavaBusinessResponseCode.MACHINE_VERIFICATION:
              console.log('需要人机校验')
              break
            case EJavaBusinessResponseCode.NOT_AUTHENTICATION:
              console.log('未认证')
              break
            case EJavaBusinessResponseCode.NOT_FULL_EIGHTEEN:
              console.log('未满十八岁')
              break
            default:
              console.log(data?.errorMsg || '')
          }
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({
            ...data,
            errorMessage,
            errorCode
          })
        } else {
          // console.log('执行成功了', data)
          resolve(data?.data)
        }
      },
      onResponseError ({ response }) {
        // 处理响应错误
        const data = response._data
        reject(data.data)
      }
    })
  })
}
