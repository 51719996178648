// 首页商品列表
export function getOrnamentList(params?: any) {
  return request({
    url: '/c5-games/lucky-item/v1/items',
    method: 'GET',
    params
  })
}
// 筛选列表
export function getFilterList(params?: any) {
  return request({
    url: '/steamtrade/sga/app/v3/filters',
    method: 'GET',
    params
  })
}
// banner列表
export function getBannerList(params?: any) {
  return request({
    url: '/uic/common/v1/banner',
    method: 'GET',
    params
  })
}

//banner数据上报
export function bannerReport(params?: any) {
  return request({
    url: '/uic/common/v1/report',
    method: 'POST',
    data: params
  })
}


// 公告
export function getNoticeInfo(params?: any) {
  return request({
    url: '/c5-games/lucky-item/v1/announcement',
    method: 'GET',
    params
  })
}
// 记录
export function getRecordList(params?: any) {
  return request({
    url: '/c5-games/lucky-item/v1/win-record',
    method: 'GET',
    params
  })
}
// 赠送硬币配置
export function getCoinConfig(params?: any) {
  return request({
    url: '/v1/playground/box/gave-gold-config',
    method: 'GET',
    open: true,
    params
  })
}

export function getCoinPrize(params?: any) {
  return request({
    url: '/v1/playground/box/initial-charge-v2-receive',
    method: 'POST',
    open: true,
    params
  })
}
// 领取硬币
export function receiveCoin(params?: any) {
  return request({
    url: '/v1/playground/box/gave-gold',
    method: 'GET',
    open: true,
    params
  })
}
// 获取用户信息
export function getUserInfo(params?: any) {
  return request({
    url: '/v1/user/my/h5',
    method: 'GET',
    open: true,
    params
  })
}
// 召回活动入口判断
export function recallShow(params?: any) {
  return request({
    url: '/c5game-marketing/v1/activity/common/activity-entry',
    method: 'GET',
    params
  })
}
// 十一活动入口判断
export function isNationalEntry(params?: any) {
  return request({
    url: '/v1/playground/activity/list',
    method: 'GET',
    open: true,
    params
  })
}
export function getAgreeContent(params?: any, parm?: any) {
  return useRequest({
    url: `/games/common/v1/agreement/${params}?inside=${parm}`,
    method: 'GET',
    parm
  })
}
// 确认营收线协议
export function confirmAgreeContent(params?: any) {
  return useRequest({
    url: `/games/common/v1/agreement/confirm/${params}`,
    method: 'POST'
  })
}
// 判断幸运饰品充值多送活动入口是否开启
export function getRechargeReward(params?: any) {
  return useRequest({
    url: `/v1/playground/recharge/reward`,
    method: 'GET',
    open: true,
    params
  })
}

// 首充活动详情
export function getRechargeFirst(params?: any) {
  return request({
    url: `/games/coin/activity/firstCharge/detail`,
    method: 'GET',
    params
  })
}

// 领取活动硬币
export function ReceiveRechargeFirst(params?: any) {
  return request({
    url: `/games/coin/activity/firstCharge/active`,
    method: 'POST',
    params
  })
}